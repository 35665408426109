/*
NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@font-face {
	font-family: 'Inter';
	src: local('Inter'), url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
  }
  
@font-face {
	font-family: 'Inter-Bold';
	font-weight: 900;
	src: local('Inter'), url(../fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter-Medium';
	src: local('Inter-Medium'), url(../fonts/Inter/Inter-Medium.ttf) format('truetype');
  }
  

.error-box {
	// display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffebee;
	padding: 16px;
	border-radius: 4px;
  }
  
  .error-icon {
	margin-right: 16px;
  }
  
  .error-message {
	text-align: center;
	color: #f44336;
  }


  .ndi-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	border-color: #124143 !important;
	background-color: #124143 !important;
	text-align: center;
  }
  
  
  
  .ndi-btn-logo {
	margin-right: 10px;
	width: 25px;
  }
  
  .ndi-title {
	font-size: 18px;
	font-family: 'Inter-Medium';
	margin-bottom: 30px;
	margin-top: 30px
  }
  .ndi-text {
	font-size: 12px;
	font-family: 'Inter';
	// text-align:'center'
  }

  .qr-code-container{
	text-align: center;
	margin:30px auto 30px auto;
  }
  .ndi-instruction-container{
	margin:30px auto 30px auto;
	width: 330px;
	// text-align: 'left';
  }

  .ndi-instruction-list li, .ndi-instruction-dont-have-app{
	font-family: 'Inter';
	font-size: 12px;
	color: #9D9D9D;
  }
  
  .ndi-instrution-download-now, .ndi-instrution-download-now:hover, .ndi-instrution-download-now:visited {
	color: #5AC994;
	font-family: 'Inter-Medium'
  }
  .ndi-instrution-download-now:hover{
	opacity: 0.8
  }
  .ndi-instruction-watch-btn-container{
	font-size: 12px;
	text-align: center
  }
  .ndi-instruction-watch-btn{
	height:30px;
	width:158px;
	text-align:center;
	display: flex; 
  	align-items: center; 
  	justify-content: center; 
	border-radius: 30px;
	background-color: #5AC994;
	color: #fff !important;
	margin:30px auto 30px auto;
	font-family: 'Inter-Medium';
	font-size: 12px;
	padding-left: 0px;
	padding-right: 0px;
	// height: 30px;
  }

  .ndi-instruction-scan-button{
	display: inline; 
	vertical-align: middle; 
	margin-left: 5px;
  }

  .ndi-divider {
  	font-family: 'Inter-Regular';
	margin-top: 30px;
	margin-bottom: 30px;

  	.ndi-divider-text {
  		font-family: 'Inter';
		font-size: 12px;
		color: #9D9D9D;
  	}
  }

  .ndi-open-wallet-here{
		font-family: 'Inter';
		font-size: 14px;
		color: #9D9D9D;
		text-align:center;
		.bhutan-ndi-text{
			color: #5AC994;
			font-family: 'Inter-Medium';	
		}
  }

  .ndi-instruction-watch-btn:hover{
	opacity: 0.8
  }



  .ndi-instruction-dont-have-app{
	text-align:center
  }
  .ndi-instruction-app-store-image-containers {
	margin-top: 10px;
  	text-align: 'center';
	display: flex;
	align-items: center;
	justify-content: center;

	img{
		// width:120px;
		height: 30px;
		margin: 0px 5px;
	}
  }

  
  .ndi-btn-text {
	font-size: 16px;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .ndi-instruction-scan-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
	img{
		float: right
	}
  }
  
  @media (max-width: 767px) {
	.ndi-btn {
	  flex-direction: column;
	}
  
	.ndi-content-container {
	  align-items: center;
	  text-align: center;
	}
  
	.ndi-btn-logo {
	  margin-right: 10;
	//   margin-bottom: 10px;
	}

	.notice-timer-container{
		display: flex;
		justify-content: space-around;
		font-family: sans-serif;
		text-align: center;
		padding-top: 40px;
	}

	.notice-modal-title {
		color: red
	}
	.notice-modal {

		.modal-header {
				display: block;
					flex: unset !important;
					flex-shrink: unset !important;
					align-items: unset !important;
					justify-content: unset !important;
			}
	} 

	
  }
  
  
  
  
	